import React from 'react';
import { Flex, Box } from 'reflexbox';
import { InputGroup, ControlGroup, Button, Intent, FormGroup } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import MomentLocaleUtils from 'react-day-picker/moment';

import * as styles from './index.module.scss';

import { Select } from '@components/ui/select';
import { useMaskDate } from '@components/ui/use-mask-date';

import { User } from '@resources/users/_user';

type Props = {
    users: User[];
    onSubmit: (query: string, date: Date, userId: number) => void;
};

export const Search = (props: Props) => {
    const [query, setQuery] = React.useState<string>('');
    const [date, setDate] = React.useState<Date>(undefined);
    const [userId, setUserId] = React.useState<number>(0);

    const maskDocumentDate = useMaskDate();

    const onSubmit = () => props.onSubmit(query, date, userId);

    React.useEffect(() => {
        onSubmit();
    }, [date]);

    return (
        <Flex>
            <Box width={['60%']}>
                <FormGroup
                    helperText="Поддерживается поиск по: номеру телефона, ФИО, номеру карточки и адресу"
                    labelFor="search"
                    labelInfo="(required)">
                    <ControlGroup>
                        <InputGroup
                            value={query}
                            id="search"
                            leftIcon="search"
                            fill={true}
                            placeholder="Поиск..."
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
                        />
                        <DateInput
                            value={date}
                            parseDate={(str) => new Date(str)}
                            formatDate={(date) => date.toLocaleDateString()}
                            locale="ru"
                            localeUtils={MomentLocaleUtils}
                            placeholder="Дата документа"
                            onChange={setDate}
                            inputProps={{
                                leftIcon: 'calendar',
                                onKeyDown: (e) => e.key === 'Enter' && onSubmit(),
                                onSubmit: onSubmit,
                                inputRef: maskDocumentDate,
                            }}
                        />
                        <Select
                            value={userId ? String(userId) : undefined}
                            placeholderLabel="- Любой сотрудник -"
                            items={props.users.map((user) => ({
                                label: user.username,
                                value: String(user.id),
                            }))}
                            onChange={(value) => {
                                setUserId(Number(value));
                            }}
                        />
                        <Button intent={Intent.PRIMARY} onClick={onSubmit}>
                            Найти
                        </Button>
                    </ControlGroup>
                </FormGroup>
            </Box>
        </Flex>
    );
};
