import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    Button,
    FormGroup,
    InputGroup,
    RadioGroup,
    Radio,
    Checkbox,
    Intent,
    MenuItem,
    Position,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { Flex, Box } from 'reflexbox';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';

import { Select } from '@components/ui/select';
import { FormLabel } from '@components/ui/form-label';
import { AppToaster } from '@components/ui/toaster';
import { useRandomId } from '@components/ui/use-random-id';
import {
    AddressSuggestCom,
    addressSuggestItemRender,
    mainInputStyle,
} from '@components/address-suggest-com';
import { useCanNav } from '@components/ui/use-can-nav';
import { LoadingOverlay } from '@components/ui/loading';
import { useMaskDate } from '@components/ui/use-mask-date';

import { GlobalState } from '@resources/reducers';
import { selectAddressSuggests, selectCardLast } from '@resources/cards/selectors';
import { PAY_METHOD } from '@resources/cards/_visit';
import {
    Card,
    CARD_TYPE,
    CITIZENSHIP_LIST,
    FAMILY_STATUS,
    FAMILY_STATUS_LABEL,
    initialCard,
    STAY_PERIOD_MONTHS,
    STAY_PERIOD_YEARS,
} from '@resources/cards/_card';
import { joinFullName, splitFullName } from '@resources/cards/helpers';
import { USER_SEX_VALUE } from '@resources/users/_user';
import { DOCUMENT_TYPES, DOCUMENT_TYPE_LABEL } from '@resources/cards/_doc-type';
import { addressSuggests, cardUpdate } from '@resources/cards/actions';
import { CardsActions } from '@resources/actions/_state';
import { selectCardsActions } from '@resources/actions/selectors';
import { reverseStrDate } from '@resources/helpers/datetime';
import { SignatureImage } from '@components/signature/signature-image';

type ExternalProps = {
    card: Card;
    onClose: () => void;
};

type Props = {
    addressSuggests: string[];
    cardsActions: CardsActions;
    cardLast: Card;
};

export const CardEditContainer = connect(mapStateToProps)((props: Props & ExternalProps) => {
    const dispatch = useDispatch();

    const [fullNameId] = useRandomId('full_name');
    const [documentNumberId] = useRandomId('document_number');
    const [documentSeriesId] = useRandomId('document_series');
    const [documentReleasedId] = useRandomId('document_released');
    const [phoneId] = useRandomId('phone');
    const [address1Id] = useRandomId('address1');
    const [address2Id] = useRandomId('address2');
    const [polisId] = useRandomId('polis');
    const [snilsId] = useRandomId('snils');
    const [medicalInsuranceOrgId] = useRandomId('medical_insurance_org');

    const maskBornDate = useMaskDate();
    const maskDocumentDate = useMaskDate();

    const [card, setCard] = React.useState<Card>(props.card);

    const [inputFullName, setInputFullName] = React.useState<string>(joinFullName(props.card));
    const [copyToActAddress, setCopyToActAddress] = React.useState<boolean>(false); // Совпадает с актуальным адресом
    const [citizenshipManual, setCitizenshipManual] = React.useState<boolean>(
        !CITIZENSHIP_LIST.includes(card.citizenship),
    );

    useCanNav(props.cardsActions.card_update.success, () => {
        resetAll();
        props.onClose();

        AppToaster.show({ message: 'Карточка обновлена', intent: Intent.PRIMARY, icon: 'tick' });
    });

    // Сброс выбранных значений по умолчанию
    const resetAll = () => {
        setInputFullName('');
        setCopyToActAddress(false);
        setCard({ ...initialCard });
    };

    const onSubmit = () => {
        dispatch(
            cardUpdate({
                card: card,
                extra: {
                    visit_causes: [],
                    prices: [],
                    visit_pay_method: PAY_METHOD.CARD,
                    visit_docs: [],
                    visit_docs_datetime: '',
                    visit_org_id: 0,
                    visit_office_id: 0,
                },
            }),
        );
    };

    const onPasteLastAddress = () => {
        setCard((prev) => ({
            ...prev,
            address1: props.cardLast.address1,
            address2: props.cardLast.address2,
        }));
    };

    // Обновление части имен
    React.useEffect(() => {
        setCard((prev) => ({ ...prev, ...splitFullName(inputFullName) }));
    }, [inputFullName]);

    // Копирование данных из актуального адреса в адрес регистрации
    React.useEffect(() => {
        if (copyToActAddress) {
            setCard((prev) => ({
                ...prev,
                address2: card.address1,
            }));
        }
    }, [copyToActAddress]);

    React.useEffect(() => {
        dispatch(addressSuggests({ address: card.address1 }));
    }, [card.address1]);

    React.useEffect(() => {
        dispatch(addressSuggests({ address: card.address2 }));
    }, [card.address2]);

    return (
        <LoadingOverlay isLoading={props.cardsActions.card_update.loading}>
            <FormLabel>Клиент:</FormLabel>
            <Flex mb={10}>
                <RadioGroup
                    inline={true}
                    onChange={(e) =>
                        setCard({
                            ...card,
                            type: e.currentTarget.value as CARD_TYPE,
                        })
                    }
                    selectedValue={card.type}>
                    <Radio label="Медобследование" inline={true} value={CARD_TYPE.RESEARCH} />
                    <Radio label="Пациент" inline={true} value={CARD_TYPE.PATIENT} />
                </RadioGroup>
            </Flex>
            <Flex mb={10}>
                <Box width={['40%']}>
                    <FormGroup
                        label="ФИО"
                        labelFor={fullNameId}
                        labelInfo="(обязательно)"
                        style={{ marginRight: 10 }}>
                        <InputGroup
                            value={inputFullName}
                            id={fullNameId}
                            autoComplete={fullNameId}
                            fill={true}
                            placeholder="Архипов Архип Архипович"
                            leftIcon="user"
                            onChange={(e) => setInputFullName(e.target.value)}
                        />
                    </FormGroup>
                </Box>
                <Box width={['15%']}>
                    <FormGroup
                        label="Дата рождения"
                        labelFor="born-date"
                        style={{ marginRight: 10 }}>
                        <DateInput
                            value={card.born_date ? new Date(card.born_date) : null}
                            fill={true}
                            parseDate={(str) => moment(reverseStrDate(str)).toDate()}
                            formatDate={(date) => date.toLocaleDateString()}
                            placeholder="01.01.2020"
                            minDate={new Date(1, 0, 1940)}
                            locale="ru"
                            localeUtils={MomentLocaleUtils}
                            onChange={(date) => {
                                if (date) {
                                    setCard((prev) => ({ ...prev, born_date: date.toISOString() }));
                                }
                            }}
                            inputProps={{
                                id: 'born-date',
                                inputRef: maskBornDate,
                            }}
                        />
                    </FormGroup>
                </Box>
                <Box width={['20%']}>
                    <FormGroup
                        label="Контактный телефон"
                        labelFor={phoneId}
                        style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.phone}
                            fill={true}
                            id={phoneId}
                            type="text"
                            placeholder="+79140000000"
                            autoComplete={phoneId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, phone: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['15%']}>
                    <FormGroup
                        label="Семейн. полож."
                        labelFor="family_status"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={card.family_status}
                            style={{ width: '100%' }}
                            items={[FAMILY_STATUS.MARRIAGE, FAMILY_STATUS.NOT_MARRIAGE].map(
                                (item) => ({
                                    label: FAMILY_STATUS_LABEL[item],
                                    value: item,
                                }),
                            )}
                            onChange={(value) =>
                                setCard((prev) => ({
                                    ...prev,
                                    family_status: value as FAMILY_STATUS,
                                }))
                            }
                            placeholderLabel="Неизвестно"
                        />
                    </FormGroup>
                </Box>
                <Box width={['20%']}>
                    <FormGroup label="Пол">
                        <RadioGroup
                            onChange={(e) => {
                                const { value } = e.currentTarget;
                                setCard((prev) => ({
                                    ...prev,
                                    sex: value === USER_SEX_VALUE[1] ? true : false,
                                }));
                            }}
                            selectedValue={USER_SEX_VALUE[Number(card.sex)]}
                            inline={true}>
                            <Radio label="Муж." value={USER_SEX_VALUE[1]} />
                            <Radio label="Жен." value={USER_SEX_VALUE[0]} />
                        </RadioGroup>
                    </FormGroup>
                </Box>
            </Flex>
            <FormLabel>Адреса:</FormLabel>
            <Box mb={15} width={['100%']}>
                <FormGroup
                    label="Фактический адрес"
                    labelFor={address1Id}
                    style={{ marginRight: 10 }}>
                    <AddressSuggestCom
                        items={props.addressSuggests}
                        itemRenderer={addressSuggestItemRender}
                        noResults={<MenuItem disabled={true} text="Не найдено" />}
                        closeOnSelect={true}
                        openOnKeyDown={true}
                        query={card.address1}
                        onItemSelect={(item) => setCard((prev) => ({ ...prev, address1: item }))}
                        onQueryChange={(value) => setCard((prev) => ({ ...prev, address1: value }))}
                        inputValueRenderer={(item) => card.address1}
                        fill={true}
                        popoverProps={{
                            fill: true,
                            position: Position.BOTTOM_LEFT,
                            usePortal: true,
                            popoverClassName: mainInputStyle,
                        }}
                        inputProps={{
                            id: address1Id,
                            autoComplete: address1Id,
                            placeholder:
                                'Россия, Республика Саха (Якутия), г. Якутск, Федора Попова, д. 23, кв. 10',

                            rightElement: (
                                <Button
                                    small={true}
                                    onClick={onPasteLastAddress}
                                    icon="clipboard"
                                    disabled={
                                        !(props.cardLast.address1 && props.cardLast.address2)
                                    }>
                                    Вставить последний адрес
                                </Button>
                            ),
                        }}
                    />
                </FormGroup>

                <FormGroup
                    label="Адрес регистрации"
                    labelFor={address2Id}
                    style={{ marginRight: 10 }}>
                    <AddressSuggestCom
                        items={props.addressSuggests}
                        itemRenderer={addressSuggestItemRender}
                        noResults={<MenuItem disabled={true} text="Не найдено" />}
                        closeOnSelect={true}
                        disabled={copyToActAddress ? true : false}
                        openOnKeyDown={true}
                        query={copyToActAddress ? card.address1 : card.address2}
                        onItemSelect={(item) => setCard((prev) => ({ ...prev, address2: item }))}
                        onQueryChange={(value) => setCard((prev) => ({ ...prev, address2: value }))}
                        inputValueRenderer={(item) => card.address2}
                        fill={true}
                        popoverProps={{
                            fill: true,
                            position: Position.BOTTOM_LEFT,
                            usePortal: true,
                            popoverClassName: mainInputStyle,
                        }}
                        inputProps={{
                            id: address2Id,
                            autoComplete: address2Id,
                            placeholder:
                                'Россия, Республика Саха (Якутия), г. Якутск, Федора Попова, д. 23, кв. 10',
                        }}
                    />
                    <Checkbox
                        checked={copyToActAddress}
                        label="Совпадает с фактическим адресом"
                        onChange={(e) => {
                            setCopyToActAddress((e.target as HTMLInputElement).checked);
                        }}
                    />
                </FormGroup>
            </Box>
            <FormLabel>Паспортные данные:</FormLabel>
            <Flex mb={10}>
                <Box width={['15%']}>
                    <FormGroup
                        label="Тип документа"
                        labelFor="document_type"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={card.document_type}
                            style={{ width: '100%' }}
                            items={DOCUMENT_TYPES.map((item) => ({
                                label: DOCUMENT_TYPE_LABEL[item],
                                value: item,
                            }))}
                            onChange={(value) =>
                                setCard((prev) => ({ ...prev, document_type: value }))
                            }
                            placeholderLabel="- Выберите документ -"
                        />
                    </FormGroup>
                </Box>
                <Box width={['10%']}>
                    <FormGroup
                        label="Серия"
                        labelFor={documentSeriesId}
                        style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.document_series}
                            id={documentSeriesId}
                            placeholder="0000"
                            autoComplete={documentSeriesId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, document_series: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['10%']}>
                    <FormGroup
                        label="Номер"
                        labelFor={documentNumberId}
                        style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.document_number}
                            id={documentNumberId}
                            placeholder="001122"
                            autoComplete={documentNumberId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, document_number: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['49%']}>
                    <FormGroup
                        label="Кем выдан"
                        labelFor={documentReleasedId}
                        style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.document_released}
                            fill={true}
                            id={documentReleasedId}
                            placeholder="Отделом УФМС России по Республике Саха (Якутия) в г. Якутск"
                            autoComplete={documentReleasedId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, document_released: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['15%']}>
                    <FormGroup label="Когда выдан" labelFor={documentReleasedId}>
                        <DateInput
                            value={card.document_date ? new Date(card.document_date) : null}
                            parseDate={(str) => moment(reverseStrDate(str)).toDate()}
                            formatDate={(date) => date.toLocaleDateString()}
                            placeholder="01.01.2020"
                            locale="ru"
                            localeUtils={MomentLocaleUtils}
                            minDate={new Date(1, 0, 1940)}
                            onChange={(date) => {
                                if (date) {
                                    setCard((prev) => ({
                                        ...prev,
                                        document_date: date.toISOString(),
                                    }));
                                }
                            }}
                            inputProps={{
                                inputRef: maskDocumentDate,
                            }}
                        />
                    </FormGroup>
                </Box>
            </Flex>

            <Flex mb={10}>
                <Box width={['35%']}>
                    <FormGroup
                        label="Гражданство"
                        labelFor="citizenship"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={card.citizenship}
                            style={{ width: '100%' }}
                            items={[...CITIZENSHIP_LIST, 'Другое'].map((item) => ({
                                label: item,
                                value: item,
                            }))}
                            onChange={(value) => {
                                if (value === 'Другое') {
                                    setCitizenshipManual(true);
                                    setCard((prev) => ({
                                        ...prev,
                                        citizenship: null,
                                    }));
                                } else {
                                    setCitizenshipManual(false);
                                    setCard((prev) => ({
                                        ...prev,
                                        citizenship: value,
                                    }));
                                }
                            }}
                            placeholderLabel="- Выберите гражданство -"
                        />
                    </FormGroup>

                    {citizenshipManual && (
                        <InputGroup
                            value={card.citizenship}
                            id={polisId}
                            placeholder="Введите гражданство"
                            onChange={(e) => {
                                setCard((prev) => ({ ...prev, citizenship: e.target.value }));
                            }}
                        />
                    )}
                </Box>
                <Box width={['20%']}>
                    <FormGroup
                        label="Срок пребывания"
                        labelFor="stay_period_years"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={card.stay_period_years}
                            style={{ width: '100%' }}
                            items={[...STAY_PERIOD_YEARS].map((item) => ({
                                label: item + ' г.',
                                value: item,
                            }))}
                            onChange={(value) => {
                                setCard((prev) => ({
                                    ...prev,
                                    stay_period_years: value,
                                }));
                            }}
                            placeholderLabel="- Год -"
                        />
                    </FormGroup>
                </Box>
                <Box width={['20%']}>
                    <FormGroup
                        label="&nbsp;"
                        labelFor="stay_period_months"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={card.stay_period_months}
                            style={{ width: '100%' }}
                            items={[...STAY_PERIOD_MONTHS].map((item) => ({
                                label: item + ' мес.',
                                value: item,
                            }))}
                            onChange={(value) => {
                                setCard((prev) => ({
                                    ...prev,
                                    stay_period_months: value,
                                }));
                            }}
                            placeholderLabel="- Месяц -"
                        />
                    </FormGroup>
                </Box>
            </Flex>

            <FormLabel>Медицинская страховка:</FormLabel>
            <Flex mb={10}>
                <Box width={['15%']}>
                    <FormGroup label="Полис" labelFor={polisId} style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.polis}
                            id={polisId}
                            placeholder="0000111122223333"
                            autoComplete={polisId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, polis: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['20%']}>
                    <FormGroup label="Снилс" labelFor={snilsId} style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.snils}
                            id={snilsId}
                            placeholder="123-456-789 00"
                            autoComplete={snilsId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, snils: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['65%']}>
                    <FormGroup
                        label="Мед. страховая организация"
                        labelFor={medicalInsuranceOrgId}
                        style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.medical_insurance_org}
                            id={medicalInsuranceOrgId}
                            placeholder="Сахамедстрах"
                            autoComplete={medicalInsuranceOrgId}
                            onChange={(e) =>
                                setCard((prev) => ({
                                    ...prev,
                                    medical_insurance_org: e.target.value,
                                }))
                            }
                        />
                    </FormGroup>
                </Box>
            </Flex>

            <FormGroup label="Подпись">
                {card.signature ? <SignatureImage uuid={card.signature} /> : 'без подписи'}
            </FormGroup>

            <Button intent={Intent.PRIMARY} onClick={onSubmit}>
                Сохранить карточку
            </Button>
        </LoadingOverlay>
    );
});

function mapStateToProps(state: GlobalState): Props {
    return {
        addressSuggests: selectAddressSuggests(state),
        cardsActions: selectCardsActions(state),
        cardLast: selectCardLast(state),
    };
}
