import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Box } from 'reflexbox';
import { Classes, Dialog, Intent } from '@blueprintjs/core';

import { CardCom } from '@components/card-com';
import { Pagination } from '@components/ui/pagination';
import { Search } from '@components/search';
import { AlertRemove } from '@components/alert-remove';
import { useCanNav } from '@components/ui/use-can-nav';
import { AppToaster } from '@components/ui/toaster';

import { Layout } from '@containers/layout';
import { CardEditContainer } from '@containers/card-edit-dialog';

import { GlobalState } from '@resources/reducers';
import { selectCard, selectCards } from '@resources/cards/selectors';
import { CARD_TYPE } from '@resources/cards/_card';
import { selectCardsPag } from '@resources/pagination/selectors';
import { cardRemove, cards } from '@resources/cards/actions';
import { selectIsAdmin, selectUsers } from '@resources/users/selectors';
import { CardsActions } from '@resources/actions/_state';
import { selectCardsActions } from '@resources/actions/selectors';
import { CardsPag } from '@resources/pagination/_state';
import { CardsFilters } from '@resources/filters/_state';
import { selectCardsFilters } from '@resources/filters/selectors';
import { User } from '@resources/users/_user';
import { useQueryParam } from '@resources/helpers/router';
import { DocsTemplatesContext, DocTemplate } from '@resources/config/_doc-template';
import { selectDocsTemplates } from '@resources/config/selectors';

type Props = {
    cardsPag: CardsPag;
    cardsActions: CardsActions;
    cardsFilters: CardsFilters;
    isAdmin: boolean;
    users: User[];
    docsTemplates: DocTemplate[];
};

export default connect(mapStateToProps)((props: Props) => {
    const dispatch = useDispatch();
    const cardType = useQueryParam('type') as CARD_TYPE;

    const [removeCardId, setRemoveCardId] = React.useState<number>(0);
    const [editCardId, setEditCardId] = React.useState<number>(0);

    const removeCard = useSelector((state: GlobalState) => selectCard(state, removeCardId));
    const editCard = useSelector((state: GlobalState) => selectCard(state, editCardId));
    const cardsList = useSelector((state: GlobalState) => selectCards(state, cardType));

    const onRemove = () => dispatch(cardRemove({ card_id: removeCardId }));

    useCanNav(props.cardsActions.visit_sms_sign.success, () => {
        AppToaster.show({
            message: 'Смс с документами отправлен',
            intent: Intent.PRIMARY,
            icon: 'notifications',
        });
    });

    const onPage = (page: number) => {
        dispatch(
            cards({
                pagination: {
                    ...props.cardsPag.cards,
                    page,
                },
                filters: {
                    ...props.cardsFilters.cards,
                    card_type: cardType,
                },
            }),
        );
    };

    const onSearch = (query: string, date: Date, userId: number) => {
        dispatch(
            cards({
                pagination: {
                    ...props.cardsPag.cards,
                    page: 0,
                },
                filters: {
                    ...props.cardsFilters.cards,
                    query: query,
                    datetime: date ? date.toISOString() : '',
                    user_id: userId,
                    card_type: cardType,
                },
            }),
        );
    };

    React.useEffect(() => {
        dispatch(
            cards({
                pagination: {
                    ...props.cardsPag.cards,
                    page: 0,
                },
                filters: {
                    ...props.cardsFilters.cards,
                    card_type: cardType,
                },
            }),
        );
    }, [cardType]);

    return (
        <Layout
            isLoading={
                props.cardsActions.cards.loading || props.cardsActions.visit_sms_sign.loading
            }
            title={
                cardType === CARD_TYPE.RESEARCH ? 'Список медобследованных' : 'Список пациентов'
            }>
            <Box mb={15}>
                <Search users={props.users} onSubmit={onSearch} />
            </Box>

            <DocsTemplatesContext.Provider value={props.docsTemplates}>
                {cardsList.map((card, index) => (
                    <CardCom
                        key={card.id}
                        card={card}
                        isAdmin={props.isAdmin}
                        onRemove={() => setRemoveCardId(card.id)}
                        onEdit={() => setEditCardId(card.id)}
                    />
                ))}
            </DocsTemplatesContext.Provider>

            <Pagination pag={props.cardsPag.cards} isLoading={false} onPage={onPage} />

            <AlertRemove
                isOpen={removeCardId !== 0}
                onClose={() => setRemoveCardId(0)}
                onConfirm={onRemove}>
                Удалить карточку{' '}
                <b>
                    {removeCard.unique_id} {removeCard.last_name} {removeCard.first_name}{' '}
                    {removeCard.patronymic}
                </b>
                ? При удалении его можно будет восстановить только через базу данных
            </AlertRemove>

            <Dialog
                isOpen={editCardId !== 0}
                style={{ width: 1000 }}
                onClose={() => setEditCardId(0)}>
                <div className={Classes.DIALOG_BODY}>
                    <CardEditContainer card={editCard} onClose={() => setEditCardId(0)} />
                </div>
            </Dialog>
        </Layout>
    );
});

function mapStateToProps(state: GlobalState): Props {
    return {
        cardsPag: selectCardsPag(state),
        cardsActions: selectCardsActions(state),
        cardsFilters: selectCardsFilters(state),
        isAdmin: selectIsAdmin(state),
        users: selectUsers(state),
        docsTemplates: selectDocsTemplates(state),
    };
}
