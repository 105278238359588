// extracted by mini-css-extract-plugin
export var container = "index-module--container--2uyAi";
export var head = "index-module--head--2G2Fk";
export var head_collapse = "index-module--head_collapse--1F51x";
export var content = "index-module--content--11KNp";
export var footer = "index-module--footer--168ue";
export var number = "index-module--number--Mw1d7";
export var username = "index-module--username--2dEQ0";
export var item = "index-module--item--Qa4nN";
export var label = "index-module--label--1E5WK";
export var value = "index-module--value--1HE9u";
export var created = "index-module--created--2YGBS";
export var created_divider = "index-module--created_divider--3lpB2";