import React from 'react';
import { Flex, Box } from 'reflexbox';
import { Button, Intent } from '@blueprintjs/core';
import moment from 'moment-timezone';

import * as styles from './index.module.scss';

import { CardVisitCom } from '@components/card-visit-com';

import { Card, FAMILY_STATUS_LABEL } from '@resources/cards/_card';
import { FORMAT_DATE } from '@resources/helpers/datetime';
import { DOCUMENT_TYPE_LABEL } from '@resources/cards/_doc-type';

type Props = {
    card: Card;

    isAdmin: boolean;

    onEdit: () => void;
    onRemove: () => void;
};

export const CardCom = (props: Props) => {
    const { document_type, document_number, document_released, document_series, document_date } =
        props.card;

    const [collapse, setCollapse] = React.useState<boolean>(false);

    return (
        <Flex width={['75%']} className={styles.container} flexDirection="column">
            <Flex
                width={['100%']}
                className={styles.head}
                onClick={() => setCollapse((prev) => !prev)}>
                <Box width={['50%']} className={styles.head_collapse}>
                    <div className={styles.number}>{props.card.unique_id}</div>
                    <div className={styles.username}>
                        {props.card.last_name} {props.card.first_name} {props.card.patronymic}
                    </div>
                </Box>
                <Box width={['50%']} alignItems="center" justifyContent="flex-end" display="flex">
                    <Button
                        intent={Intent.PRIMARY}
                        style={{ marginRight: props.isAdmin ? 10 : 0 }}
                        icon="edit"
                        onClick={props.onEdit}>
                        Изменить
                    </Button>
                    {props.isAdmin && (
                        <Button icon="trash" onClick={props.onRemove}>
                            Удалить
                        </Button>
                    )}
                </Box>
            </Flex>
            {collapse ? (
                <React.Fragment>
                    <div className={styles.content}>
                        <CardComValue label="Телефон">{props.card.phone}</CardComValue>
                        <CardComValue label="Пол">
                            {props.card.sex ? 'Мужской' : 'Женский'}
                        </CardComValue>
                        <CardComValue label="Дата рождения">
                            {moment.utc(props.card.born_date).local().format(FORMAT_DATE)}
                        </CardComValue>
                        <CardComValue label="Семейное полож.">
                            {FAMILY_STATUS_LABEL[props.card.family_status]}
                        </CardComValue>
                        <CardComValue label="Документ">
                            <div>
                                {DOCUMENT_TYPE_LABEL[document_type]}, серия {document_series} номер{' '}
                                {document_number}
                            </div>
                            <div>
                                Выдан {document_released}{' '}
                                {moment.utc(document_date).unix() > 0 &&
                                    moment.utc(document_date).local().format(FORMAT_DATE)}
                            </div>
                        </CardComValue>
                        <CardComValue label="Полис">{props.card.polis}</CardComValue>
                        <CardComValue label="Снилс">{props.card.snils}</CardComValue>
                        <CardComValue label="Мед. страховая орг.">
                            {props.card.medical_insurance_org}
                        </CardComValue>
                        <CardComValue label="Факт. адрес">{props.card.address1}</CardComValue>
                        <CardComValue label="Адрес регистр.">{props.card.address2}</CardComValue>
                        <CardComValue label="Гражданство">{props.card.citizenship}</CardComValue>
                        <CardComValue label="Срок пребывания">
                            {props.card.stay_period_years} год(лет) и{' '}
                            {props.card.stay_period_months} мес.
                        </CardComValue>
                    </div>

                    <div className={styles.footer}>
                        {props.card._visits.map((visit, index) => (
                            <CardVisitCom
                                key={visit.id}
                                visit={visit}
                                index={props.card._visits.length - 1 - index}
                            />
                        ))}
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment />
            )}
        </Flex>
    );
};

export const CardComValue = (props: {
    label: string;
    children: string | React.ReactChild | React.ReactChild[];
}) => (
    <Flex display="flex" flexDirection="row" mb={1} className={styles.item}>
        <div className={styles.label}>{props.label}</div>
        <div className={styles.value}>{props.children ? props.children : '-'}</div>
    </Flex>
);
