import React from 'react';
import { Box } from 'reflexbox';
import moment from 'moment-timezone';
import {
    ControlGroup,
    Icon,
    Classes,
    Dialog,
    Intent,
    Button,
    H5,
    Checkbox,
    ButtonGroup,
} from '@blueprintjs/core';

import * as styles from './index.module.scss';

import { CardComValue } from '@components/card-com';
import { usePrintDocument } from '@components/use-print-document';

import { PAY_METHOD_LABEL, Visit } from '@resources/cards/_visit';
import { FORMAT_DATETIME } from '@resources/helpers/datetime';
import { CAUSE_LABEL } from '@resources/cards/_cause';
import { DocsTemplatesContext } from '@resources/config/_doc-template';
import { issetArrayItem, toogleArrayItem } from '@resources/helpers/array';
import { useDocumentDownload } from '@resources/cards/use-document-download';

type Props = {
    visit: Visit;
    index: number;
};

export const CardVisitCom = (props: Props) => {
    const docsTemplates = React.useContext(DocsTemplatesContext);

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [docs, setDocs] = React.useState<string[]>(props.visit.docs);
    const { downloadDocument, deleteDocument, downloadDocumentByDocs, loadingDocument } =
        useDocumentDownload();

    const onPrint = () => {
        downloadDocumentByDocs(props.visit.id, docs).then(() => {
            setIsOpen(false);
        });
    };

    const onDelete = (uuid: string) => {
        if (confirm('Удалить документ? Его будет нельзя восстановить')) {
            deleteDocument(uuid);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>Прием №{props.index + 1}</div>
            <CardComValue label="Услуги">
                {props.visit._prices
                    .map((price, index) => {
                        const { name, name_short } = price;

                        // Для совместимости
                        return `${name_short ? name_short : name} ${price.price} р`;
                    })
                    .join(', ')}
            </CardComValue>
            <CardComValue label="Основания">
                {props.visit.causes.map((cause) => CAUSE_LABEL[cause]).join(', ')}
            </CardComValue>
            <CardComValue label="Итого">{props.visit.total} р.</CardComValue>
            <CardComValue label="Метод оплаты">
                {PAY_METHOD_LABEL[props.visit.pay_method]}
            </CardComValue>
            <CardComValue label="Офис осмотра">{props.visit._office.name}</CardComValue>
            <CardComValue label="Организация">{props.visit._org.name}</CardComValue>
            <CardComValue label="Осмотрел(-а)">{props.visit._user.username}</CardComValue>
            <CardComValue label="Дата и время">
                {moment.utc(props.visit.docs_datetime).local().format(FORMAT_DATETIME)}
            </CardComValue>
            <CardComValue label="Документы">
                {props.visit._docs_templates.map((t) => t.label).join(', ')}
            </CardComValue>

            <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
                <ButtonGroup>
                    <Button
                        alignText="left"
                        small={true}
                        intent={Intent.NONE}
                        icon="print"
                        onClick={() => setIsOpen(true)}>
                        Создать документ
                    </Button>
                </ButtonGroup>

                {props.visit._documents.map((document) => (
                    <ButtonGroup key={document.uuid}>
                        <Button
                            alignText="left"
                            small={true}
                            intent={Intent.NONE}
                            icon="print"
                            disabled={loadingDocument}
                            onClick={() => downloadDocument(props.visit.id, document.uuid)}>
                            от {moment.utc(document.created_at).local().format(FORMAT_DATETIME)}
                        </Button>
                        <Button
                            alignText="left"
                            small={true}
                            intent={Intent.NONE}
                            disabled={loadingDocument}
                            style={{ marginRight: 5 }}
                            icon="cross"
                            onClick={() => onDelete(document.uuid)}
                        />
                    </ButtonGroup>
                ))}
            </div>

            <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <div className={Classes.DIALOG_HEADER}>
                    <H5>Новый документ</H5>
                </div>
                <div className={Classes.DIALOG_BODY}>
                    {docsTemplates.map((t) => (
                        <Checkbox
                            key={t.id}
                            checked={issetArrayItem(docs, t.name)}
                            label={t.label}
                            onChange={() => {
                                setDocs(toogleArrayItem(docs, t.name));
                            }}
                        />
                    ))}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <Button
                        alignText="left"
                        intent={Intent.PRIMARY}
                        disabled={!docs.length || loadingDocument}
                        style={{ marginRight: 5 }}
                        icon="print"
                        onClick={onPrint}>
                        Создать документ
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};
