import React from 'react';

export interface DocTemplate {
    id: number;
    name: string;
    label: string;
}

export const initialDocTemplate: DocTemplate = {
    id: 0,
    name: '',
    label: '',
};

export const DocsTemplatesContext = React.createContext<DocTemplate[]>([]);
