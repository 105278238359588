export enum CAUSE {
    RESIDENCE = 'residence',
    RESIDENCE_TEMP = 'residence_temp',
    PATENT = 'patent',
    CIVIL = 'civil',
}

export const CAUSE_LABEL = {
    [CAUSE.RESIDENCE]: 'РВП',
    [CAUSE.RESIDENCE_TEMP]: 'ВНЖ',
    [CAUSE.PATENT]: 'Патент',
    [CAUSE.CIVIL]: 'Гражданство',
};

export const CAUSES = [CAUSE.RESIDENCE, CAUSE.RESIDENCE_TEMP, CAUSE.PATENT, CAUSE.CIVIL];
